.Container {
    max-width: 1146px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
}

.Container__withPadding {
    padding-top: var(--base-padding);
}

.Container__narrow {
    max-width: 705px;
}
