.Term-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Term-type {
    font-size: 0.8rem;
}

.Term-section {
    margin: var(--base-padding) 0;
}

.Term-mainArea {
    margin-bottom: var(--base-padding);
}

.Term-error {
    margin-top: var(--base-padding);
}
