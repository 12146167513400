.Checkbox {
    display: flex;
    align-items: center;
    gap: var(--tiny-gap);
    cursor: pointer;
}

.Checkbox-box {
    width: 24px;
    height: 24px;
    color: var(--primary-light-color);
    background: none;
    border: 0;
    outline: 0;
    flex-grow: 0;
    background-color: #ffffff;
    cursor: pointer;
}

.Checkbox-box::before {
    content: '';
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 2px solid var(--primary-light-color);
    background-color: transparent;
    background-size: contain;
}

.Checkbox-box:checked {
    background-color: currentcolor;
}

.Checkbox-box:checked::before {
    box-shadow: none;
    font-family: 'dfo-symbols', sans-serif !important;
    content: '\e900' !important;
    color: #ffffff;
    font-size: 90%;
    text-align: center;
    line-height: 1.1rem;
}

.Checkbox-box:hover::before {
    border-color: var(--secondary-color);
}

.Checkbox-box:checked:hover:not(:focus):before {
    background-color: var(--secondary-color);
}

.Checkbox-box:checked:focus::before {
    background-color: var(--primary-light-color);
    border-color: var(--primary-light-color);
}

.Checkbox-box:focus ~ .Checkbox-label {
    outline: 1px dotted #000;
}

.Checkbox-label {
    white-space: nowrap;
    overflow: hidden;
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
    display: block;
    text-overflow: ellipsis;
    border: 1 solid transparent;
}
