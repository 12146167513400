.Forside-search {
    padding: var(--big-padding) 0 var(--base-padding);
}

.Forside-search-wrapper {
    max-width: 66%;
}

.Forside-search-container {
    background-image: url('../../../public/img/robotisering.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - var(--base-padding)) 0;
    background-size: contain;
    min-height: calc(var(--big-padding) * 3.5);
}

.Forside-welcomeText {
    color: var(--primary-color);
    margin: var(--base-padding) 0;
}

.Forside-shortcuts-grid {
    padding: var(--base-padding) 0;
}

.Forside-shortcuts-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    gap: var(--base-gap);
}

@media only screen and (max-width: 1024px) {
    .Forside-shortcuts-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 600px) {
    .Forside-search {
        padding: var(--base-padding) 0;
        background-color: transparent;
    }

    .Forside-shortcuts-grid {
        padding: var(--base-padding) 0;
    }

    .Forside-search-wrapper {
        max-width: 100%;
    }

    .Forside-shortcuts-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .Forside-search-container {
        background-image: none;
    }
}
