.GlossaryResults-result {
    margin-bottom: var(--base-padding);
    padding: var(--small-padding);
    background-color: #fff;
    border: 2px solid var(--border-color-secondary);
}

.GlossaryResults-result-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--tiny-gap);
    align-items: center;
}
.GlossaryResults-result-header h3 {
    margin: 0;
}

.GlossaryResults-link {
    color: var(--primary-color);
    font-size: 1.22rem;
    font-weight: 600;
}

a.GlossaryResults-link:link,
a.GlossaryResults-link:visited,
a.GlossaryResults-link:hover,
a.GlossaryResults-link:active {
    color: var(--primary-color);
}

@media only screen and (max-width: 768px) {
    .GlossaryResults-result-header {
        flex-direction: column;
        align-items: flex-start;
    }
}
