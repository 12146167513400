.Search-searchbar {
    padding-top: var(--tiny-padding);
    padding-bottom: var(--tiny-padding);
    margin-left: calc(var(--sidebar-width) + var(--base-gap));
}

.Search-expandFilter {
    display: none;
}

.Search-expandFilter > button {
    white-space: nowrap;
    width: 120px;
    text-align: center;
    padding: var(--tiny-padding) var(--tiny-padding);
    font-size: 1rem;
    position: relative;
}

.Search-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: var(--base-gap);
}

.Search-field {
    flex-grow: 1;
    width: 100%;
}

.Search-filter {
    margin-top: var(--base-padding);
}

@media only screen and (max-width: 768px) {
    .Search-expandFilter {
        display: block;
    }

    .Search-filter__collapsed {
        display: none;
    }

    .Search-filter__expanded {
        display: block;
    }
}
