.Header {
    border-bottom: 2px solid #e6e6e6;
    background-color: #fff;
    padding: 1rem 0;
}

.Header-wrapper {
    display: flex;
    width: 100%;
    max-width: var(--app-max-width);
    justify-content: space-between;
    position: relative;
}

.Header-menu {
    display: flex;
}

.Header-logo {
    align-self: center;
    display: grid;
    grid-template-areas:
        'logo   border  name'
        'logo   border  subtext';
    color: var(--primary-color);
    height: 3rem;
    text-decoration: none;
}

.Header-logo:hover {
    text-decoration: none;
}

.Header-logoImage {
    align-self: center;
    grid-area: logo;
    width: 110px;
}

.Header-logoBorder {
    display: block;
    grid-area: border;
    width: 2px;
    background: var(--primary-color);
    margin: 0 var(--small-gap);
}

.Header-logoTitle {
    grid-area: name;
    font-family: var(--primary-font);
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.7rem;
    height: 1.7rem;
    margin: 0;
    color: var(--primary-color);
}

.Header-logoSubtitle {
    grid-area: subtext;
    font-size: 0.9rem;
    margin: 0;
    color: var(--primary-color);
}

@media only screen and (max-width: 768px) {
    .Header-logoTitle,
    .Header-logoSubtitle,
    .Header-logoBorder {
        display: none;
    }
}

.Header::after {
    content: attr(data-beta-label);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 50% 50%;
    transform: rotate(-45deg) translateX(-3rem) translateY(-2rem);
    font-size: 0.8rem;
    line-height: 1.5rem;
    background-color: var(--tertiary-color);
    color: #fff;
    text-align: center;
    width: 10rem;
}
