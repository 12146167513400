.NavigationLinkList {
    margin: 10px 0 20px 0;
    padding: 0;
}

.NavigationLinkList-item {
    list-style: none;
    width: 100%;
    display: block;
    border-top: 1px solid var(--secondary-color);
}

.NavigationLinkList-item:last-child {
    border-bottom: 1px solid var(--secondary-color);
}
