a.Shortcut {
    display: flex;
    border: 2px solid var(--border-color-secondary);
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    padding: var(--small-padding);
    flex-direction: column;
    gap: var(--tiny-gap);
    font-size: 1.22rem;
    justify-content: center;
}

a.Shortcut:hover {
    border-color: #fff;
}

a.Shortcut:focus {
    border-color: #fff;
    outline: 1px solid #fff;
}

.Shortcut_primary {
    background-color: var(--primary-color);
}

.Shortcut_secondary {
    background-color: var(--secondary-color);
}

.Shortcut_tertiary {
    background-color: var(--tertiary-color);
}

a.Shortcut_tertiary:hover,
a.Shortcut_tertiary:focus {
    border-color: var(--tertiary-color);
}

.Shortcut-title {
    display: flex;
    justify-content: space-between;
}

.Shortcut-icon {
    flex: 1 0 auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 4rem;
}

.Shortcut-icon:before {
    content: '';
    display: block;
    padding-top: 100%;
}

@media only screen and (max-width: 1024px) {
    .Shortcut {
        margin: 0 var(--base-padding);
    }

    .Shortcut-icon {
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
    .Shortcut {
        margin: 0;
    }
}
