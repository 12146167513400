@font-face {
    font-family: 'dfo-symbols';
    src: url('../public/fonts/symbols.ttf?61ijhl') format('truetype'),
        url('../public/fonts/symbols.woff?61ijhl') format('woff'),
        url('../public/fonts/symbols.svg?61ijhl#symbols') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'dfo-symbols', sans-serif !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-equalizer:before {
    content: '\e91d';
}
.icon-download:before {
    content: '\e91b';
}
.icon-share:before {
    content: '\e91c';
}
.icon-reading:before {
    content: '\e91a';
}
.icon-chevron-right:before {
    content: '\e916';
}
.icon-chevron-left:before {
    content: '\e917';
}
.icon-chevron-down:before {
    content: '\e918';
}
.icon-chevron-up:before {
    content: '\e919';
}
.icon-menu:before {
    content: '\e910';
}
.icon-cards:before {
    content: '\e911';
}
.icon-close-filled:before {
    content: '\e912';
}
.icon-search:before {
    content: '\e913';
    color: #009fe3;
}
.icon-calendar:before {
    content: '\e914';
    color: #009ee1;
}
.icon-list:before {
    content: '\e915';
}
.icon-close:before {
    content: '\e90f';
}
.icon-warning:before {
    content: '\e90e';
}
.icon-check-thick:before {
    content: '\e90d';
}
.icon-cloud-check:before {
    content: '\e90b';
}
.icon-cloud-sync:before {
    content: '\e90c';
}
.icon-trash:before {
    content: '\e90a';
}
.icon-envelope:before {
    content: '\e909';
}
.icon-check:before {
    content: '\e900';
}
.icon-eye:before {
    content: '\e901';
}
.icon-rocket:before {
    content: '\e902';
}
.icon-plus-circle:before {
    content: '\e903';
}
.icon-pencil:before {
    content: '\e904';
}
.icon-arrow-up:before {
    content: '\e905';
}
.icon-arrow-right:before {
    content: '\e906';
}
.icon-arrow-down:before {
    content: '\e907';
}
.icon-arrow-left:before {
    content: '\e908';
}
