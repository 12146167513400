.Layout {
    display: flex;
    gap: var(--base-gap);
}

@media only screen and (max-width: 768px) {
    .Layout {
        flex-direction: column;
    }
}
