/* .Searchbox {
    display: flex;
} */
/*
.Searchbox-input {
    background-color: #fff;
    border: 2px solid var(--primary-light-color);
}

.Searchbox-button {
    background-color: var(--primary-light-color);
    color: #fff;
    padding: 0.4rem var(--tiny-padding);
} */

.Searchbox-wrapper {
    display: flex;
}

input[type='search'].Searchbox-input {
    position: relative;
    width: 100%;
    padding: var(--small-padding) var(--tiny-padding);
    font-size: 1rem;
}

.Searchbox-input::after,
.Searchbox-input::before {
    margin: 0;
}

.Searchbox_lg input[type='search'].Searchbox-input {
    padding: var(--base-padding) var(--small-padding);
    font-size: 1.22rem;
}

.Searchbox-button {
    position: relative;
    cursor: pointer;
    background-color: transparent;
    padding: 0;
}

.Searchbox-buttonIcon {
    position: absolute;
    right: var(--tiny-padding);
    padding: var(--small-padding) var(--small-padding);
    background-color: transparent;
    border: none;
    background-image: url('../../icons/search-blue.svg');
    background-position: center right;
    background-repeat: no-repeat;
    background-size: 50%;
    top: calc(50% - var(--small-padding));
    cursor: pointer;
}

.Searchbox_lg .Searchbox-buttonIcon {
    padding: var(--base-padding) var(--small-padding);
    right: var(--small-padding);
    top: calc(50% - var(--base-padding));
    font-size: 1.2rem;
    background-size: 70%;
}
