:root {
    --background-color: #f7f7f7;
    --background-color-secondary: rgba(196, 196, 196, 0.44);
    --background-color-overlay: rgba(247, 247, 247, 0.75);
    --background-color-filtering: #efefef;
    --background-color-chips: #979797;

    --frontpage-header-color: #bfd6e3;

    --primary-color: #012a4c;
    --primary-light-color: #005b91;
    --secondary-color: #009ee1;
    --secondary-light-color: #009fe3;
    --tertiary-color: #00a982;
    --error-color: #e83f53;
    --warning-color: #f7b715;
    --disabled-color: #bbbbbb;
    --frontpage-header-color: #bfd6e3;
    --border-color: #798281;
    --border-color-secondary: #d1d1d1;
    /* --text-color: #0e2a49; */
    --text-color: #333333;
    --text-label-color: #333333;
    --link-color: var(--primary-light-color);
    --link-hover-color: var(--secondary-color);
    --line-color: #bcbcbc;
    --line-color-dark: #8898a6;

    --primary-font: 'Source Sans Pro', sans-serif;
    --secondary-font: 'Source Serif Pro', serif;

    --thin: 200;
    --regular: 400;
    --semibold: 500;
    --bold: 600;

    --base-padding: 1.5rem;
    --base-gap: 1.5rem;
    --small-gap: 1rem;
    --tiny-gap: 0.5rem;
    --tiny-padding: 0.5rem;
    --small-padding: 1.2rem;
    --medium-padding: 3rem;
    --big-padding: 4rem;

    --font-size-base: 18px;

    --sidebar-width: 220px;
}

@media only screen and (max-width: 1024px) {
    :root {
        --big-padding: 3.5rem;
    }
}

@media only screen and (max-width: 768px) {
    :root {
        --base-gap: 1rem;
        --small-gap: 0.5rem;
    }
}

@media only screen and (max-width: 600px) {
    :root {
        /* --base-padding: 0.9rem; */
    }
}

* {
    box-sizing: border-box;
}

html {
    font-size: var(--font-size-base);
}

.App {
    display: flex;
    flex-direction: column;
}

.App-maincontent {
    margin-bottom: var(--base-padding);
}

html,
body,
#root,
.App {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    background: var(--background-color);
    color: var(--text-color);
    font-family: var(--primary-font);
    line-height: 1.5;
}

a,
a:active,
a:visited,
a:hover {
    color: var(--link-color);
}

a:hover {
    text-decoration: underline;
}

table {
    width: 100%;
}

thead {
    border-bottom: 2px solid var(--border-color-secondary);
}

tr {
    border-bottom: 1px solid var(--border-color-secondary);
}

tr:first-child {
    border-top: 1px solid var(--border-color-secondary);
}

th {
    font-weight: 600;
    text-align: left;
}

td,
th {
    padding: var(--tiny-gap) var(--small-gap);
}

@media only screen and (max-width: 600px) {
    table.horizontal th,
    table.horizontal td {
        display: block;
        padding: 0;
    }

    table.horizontal th {
        font-weight: bold;
    }

    table.horizontal th::after {
        content: ': ';
    }

    table.horizontal thead,
    table.horizontal tr,
    table.horizontal tr:first-child {
        border: none;
        margin-bottom: var(--tiny-gap);
        display: block;
    }
}

code {
    font-family: Consolas, 'Courier New', Courier, monospace;
    font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--secondary-font);
    font-weight: 600;
    color: var(--primary-color);
    margin: var(--base-padding) 0 var(--tiny-padding);
}

h1 {
    font-size: 2.778rem;
    margin: var(--tiny-padding) 0;
}

h2 {
    font-size: 1.222rem;
}

h3 {
    font-size: 1rem;
}

h4 {
    font-size: 1.3rem;
    font-weight: 400;
    font-size: 1.222rem;
}
h5 {
    font-size: 1.2rem;
    font-weight: 400;
}
h6 {
    font-size: 1rem;
}

@media only screen and (max-width: 500px) {
    h1 {
        font-size: 2.2rem;
    }
}

ol,
ul {
    margin: 1.5rem 0;
    padding-left: 2rem;
}

ul > li {
    list-style: disc;
}

input[type='number'],
input[type='password'],
input[type='search'],
input[type='text'],
textarea {
    width: 100%;
    max-width: 100%;
    height: 1rem;
    padding: var(--small-padding) var(--tiny-padding);
    box-sizing: border-box;
    font-family: var(--primary-font);
    border: 2px solid var(--primary-light-color);
    outline-color: none;
    background-color: #fff;
}

input[type='number']:focus,
input[type='password']:focus,
input[type='search']:focus,
input[type='text']:focus,
textarea:focus {
    border: 2px solid var(--secondary-color);
}

textarea {
    padding: var(--small-padding) var(--tiny-padding);
    border: 2px solid var(--border-color);
    outline-color: var(--secondary-color);
}

select {
    appearance: none;
    position: relative;
    width: 100%;
    max-width: 75.6rem;
    margin-bottom: 0.7rem;
    padding: 1rem 5rem 1rem 2rem;
    border: 0.2rem solid var(--border-color);
    box-sizing: border-box;
    background-position: 95% 55%;
    background-size: 2rem;
    background-repeat: no-repeat;
    font-family: var(--font-family);
    font-size: 1.8rem;
    font-weight: 400;
    outline-color: var(--secondary-color);
}

button {
    padding: var(--small-gap) var(--tiny-gap);
    border: 0;
    background-color: var(--primary-light-color);
    color: white;
    font-family: var(--primary-font);
}

button:disabled,
.btn:disabled {
    background-color: var(--disabled-color);
}

.sr-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}
